/* eslint-disable react/jsx-filename-extension */
import { Box, Container, Grid } from '@material-ui/core';
import * as Sentry from '@sentry/node';
import NextErrorComponent from 'next/error';
import PropTypes from 'prop-types';
import React from 'react';
import Page from '../components/Page';
import { Footer, MainNavigation } from '../components/StaticPageComponents';
import { captureExceptionWithState } from '../util/sentry';
import isBrowser from '../util/isBrowser';
import Typography from '../components/Typography';

function ErrorPage({
  statusCode,
  hasGetInitialPropsRun,
  err,
  message,
  children,
}) {
  let derivedMessage = message;
  if (!message && statusCode === 404)
    derivedMessage = `Oops, the page you were looking for is not here!`;
  if (!derivedMessage) derivedMessage = `Oops, we encountered an error`;

  /**
   * Taken from next.js's implementation of Sentry
   * @see https://github.com/vercel/next.js/blob/7fd04c956d/examples/with-sentry/pages/_error.js
   */
  if (!hasGetInitialPropsRun && err && isBrowser) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
    // Flushing is not required in this case as it only happens on the client
  }

  return (
    <Page title="Error" id="page-error">
      <MainNavigation />
      <Container>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ minHeight: '80vh' }}
        >
          <Grid item xs={12} sm={10} md={8}>
            <Box textAlign="center">
              <Typography isLegacy variant="h2" color="textSecondary">
                {derivedMessage}
              </Typography>
            </Box>
            {children}
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </Page>
  );
}

ErrorPage.displayName = 'Error';

ErrorPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
  message: PropTypes.string,
  children: PropTypes.node,
  hasGetInitialPropsRun: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  err: PropTypes.object,
};

ErrorPage.defaultProps = {
  message: undefined,
  children: undefined,
  hasGetInitialPropsRun: undefined,
  err: undefined,
};

ErrorPage.getInitialProps = async ({
  req,
  res: initialRes,
  err: initialErr,
  asPath,
}) => {
  const ssr = typeof req !== 'undefined';

  if (ssr && req.log) {
    req.log.error(initialErr);
  } else if (ssr) {
    // eslint-disable-next-line no-console
    console.error(initialErr);
  }

  /**
   * Taken from next.js's implementation of Sentry
   * @see https://github.com/vercel/next.js/blob/7fd04c956d/examples/with-sentry/pages/_error.js
   */
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    initialRes,
    initialErr,
  });

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (initialErr) {
    Sentry.captureException(initialErr);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    try {
      await Sentry.flush(2000);
    } catch (error) {
      console.error(`Sentry flush error, initialErr: `, error);
    }

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  captureExceptionWithState(
    new Error(`_error.js getInitialProps missing data`),
    {
      type: 'ErrorPage',
      value: {
        asPath,
        ssr,
      },
    }
  );
  try {
    await Sentry.flush(2000);
  } catch (error) {
    console.error(`Sentry flush error: `, error);
  }
  const { res, err } = errorInitialProps;
  // eslint-disable-next-line no-nested-ternary
  errorInitialProps.statusCode = res
    ? res.statusCode
    : err
    ? err.statusCode
    : 404;

  return errorInitialProps;
};

export default ErrorPage;
